<template>
	<b-row
	class="home">
		<b-col
		md="11"
		lg="10"
		xl="9">
			<categories></categories>

			<featured></featured>
			
			<!-- <social-links></social-links> -->
		</b-col>
	</b-row>
</template>
<script>
// Components
import Categories from '@/components/buyer/home/components/Categories'
import Featured from '@/components/buyer/home/components/Featured'
import SocialLinks from '@/components/buyer/home/components/SocialLinks'
export default {
	components: {
		Categories,
		Featured,
		SocialLinks,
	},
}
</script>
<style lang="sass">
.home
    min-height: 100vh
</style>