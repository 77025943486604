<template>
	<div>
		<p class="title">
			Categorias
		</p>
		<display
		:properties="properties"
		:show_created_at="false"
		display="cards"
		model_name="category"
		:set_model_on_click="false"
		@clicked="setSelectedCategory" />
	</div>
</template>
<script>
export default {
	components: {
		Display: () => import('@/common-vue/components/display/Index'),
	},
	computed: {
		properties() {
			return [
				{
					text: 'Imagen',
					key: 'image_url',
					type: 'image',
					show: true,
				},
				{
					text: 'Nombre',
					key: 'name',
					show: true,
				},
				{
					text: 'Descripcion',
					key: 'description',
					show: true,
				},
			]
		},
	},
	methods: {
		setSelectedCategory(category) {
			console.log(category)
			this.$store.commit('category/setSelected', [category])
			this.$router.push({name: 'buyerProducts'})
		}
	}
}
</script>