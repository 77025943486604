<template>
	<div
	v-if="featured.length">
		<p class="title">
			Destacados
		</p>
		<cards-component
		:properties="properties"
		@clicked="toProduct"
		model_name="product"
		:models="featured"/>
	</div>
</template>
<script>
import buyer from '@/mixins/buyer'
export default {
	mixins: [buyer],
	components: {
		CardsComponent: () => import('@/common-vue/components/display/cards/Index'),
	},
	computed: {
		categories() {
			return this.$store.state.category.models 
		},
		featured() {
			let featured = []
			this.categories.forEach(category => {
				category.products.forEach(product => {
					if (product.featured) {
						featured.push(product)
					}
				})
			})
			return featured
		},
		properties() {
			return [
				{
					text: 'Imagenes',
					key: 'images',
					type: 'images',
					value: '',
					show: true,
				},
				{
					text: 'Nombre',
					key: 'name',
					type: 'text',
					value: '',
					show: true,
				},
				{
					text: 'Descripcion',
					key: 'description',
					type: 'textarea',
					value: '',
					show: true,
				},
				{
					text: 'Precio',
					key: 'price',
					type: 'number',
					is_price: true,
					value: '',
					show: true,
				},
			]
		}
	}
}
</script>